import React, { ReactElement } from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { PageHeaderComposition } from '@csv/styleguide/src/features/page-header/page-header';
import Heading from '@csv/styleguide/src/elements/heading/heading';
import Table from '@csv/styleguide/src/elements/table/table';
import Section from '@csv/styleguide/src/elements/section/section';
import Container from '@csv/styleguide/src/elements/container/container';

function PracticeTablePage(): ReactElement {
  return (
    <Layout>
      <SEO
        title="Trainingstijden"
        description="Overzicht van alle trainingen van alle CSV-teams."
      />
      <PageHeaderComposition
        title="Trainingstijden"
        byline="Trainingsschema's van alle CSV-teams."
      />
      <Container>
        <Section>
          <p>
            Bij interesse in (vacante) trainersposities kan contact worden
            opgenomen met de jeugdcommissie via&nbsp;
            <a href="mailto:Jeugdcommissie@csvhandbal.nl">
              Jeugdcommissie@csvhandbal.nl
            </a>
          </p>
        </Section>
        <Section>
          <Heading variant="h2" as="h2">
            Veldseizoen 2024 - 2025
          </Heading>
          <ul>
            <li>
              Voor de jeugd start het buitenseizoen start op 26 augustus 2024.
              De zaaltrainingen voor de jeugd starten in de week van 15 oktober
              2024.
            </li>
            <li>
              Het veld-voorjaarsseizoen start op 26 maart 2025 tot en met eind
              competitie.
            </li>
          </ul>
          <Table minWidth="100%" bordered>
            <thead>
              <tr>
                <td></td>
                <th scope="col">Maandag</th>
                <th scope="col">Dinsdag</th>
                <th scope="col">Woensdag</th>
                <th scope="col">Donderdag</th>
                <th scope="col">Vrijdag</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">DA1 + DB1</th>
                <td></td>
                <td>
                  19:00 - 20:00
                  <br />
                  op veld 1
                  <br />
                  <>Marcel, Sandy</>
                </td>
                <td></td>
                <td>
                  19:30 - 20:00 <br />
                  op veld 2 door Petri
                  <hr />
                  20:00 - 20:30
                  <br />
                  op veld 1 door Petri
                </td>
                <td></td>
              </tr>

              <tr>
                <th scope="row">HB1</th>
                <td></td>
                <td>
                  18:30 - 19:30
                  <br />
                  op veld 2 door Joost
                </td>

                <td></td>
                <td>
                  20:30 - 22:00
                  <br />
                  op veld 1 door Marco (samen met HS1)
                </td>
                <td></td>
              </tr>
              <tr>
                <th scope="row">DC1 + DC2</th>
                <td></td>
                <td>
                  18:00 - 19:00
                  <br />
                  op veld 1 door Rene
                </td>

                <td></td>
                <td>
                  18:30 - 19:30
                  <br />
                  DC2 op veld 2 door Michael
                  <hr />
                  <strong>
                    18.30 - 19.45 <br />
                    DC1 bij Meervogels, trainer Hans Bol
                  </strong>
                </td>
                <td></td>
              </tr>
              <tr>
                <th scope="row">D1</th>
                <td></td>
                <td>
                  17:30 - 18:30
                  <br />
                  op veld 2 door Marga + Rob
                </td>
                <td>
                  17:30 - 18:30
                  <br />
                  samen met HC op veld 1 door Jeroen en Max
                  <br />
                </td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <th scope="row">HC</th>
                <td></td>
                <td></td>
                <td>
                  17:30 - 18:30
                  <br />
                  samen met D1 op veld 1 door Jeroen en Max
                  <br />
                </td>
                <td>
                  19:00 - 20:00 <br />
                  op veld 1 door Jeremy
                </td>
                <td></td>
              </tr>

              <tr>
                <th scope="row">E-jeugd</th>
                <td></td>
                <td>
                  17:00 - 18:00
                  <br />
                  op veld 1 door René
                </td>
                <td></td>
                <td>
                  18:00 - 19:00
                  <br />
                  op veld 1 door Iris
                </td>
                <td></td>
              </tr>
              <tr>
                <th scope="row">F-jeugd</th>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>
                  18:00 - 19:00
                  <br />
                  op veld 1 door Melany en Annabel
                </td>
              </tr>
            </tbody>
          </Table>
        </Section>
        <Section>
          <Heading variant="h2" as="h2">
            Zaalseizoen 2024 - 2025
          </Heading>
          <ul>
            <li>
              Zaaltrainingen voor de jeugd start 15-10-2024 en lopen tot en met
              25-03-2025.
            </li>
            <li>
              De Rolstolhandbalteams trainen vanaf 12-09-2024 tot en met
              24-06-2025 in de zaal.
            </li>
            <li>
              Heren senioren trainen vanaf 12-09-2024 tot en met 17-04-2024.
            </li>
          </ul>
          <Table minWidth="100%" bordered>
            <thead>
              <tr>
                <td></td>
                <th scope="col">Maandag</th>
                <th scope="col">Dinsdag</th>
                <th scope="col">Woensdag</th>
                <th scope="col">Donderdag</th>
                <th scope="col">Vrijdag</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">Rolstoelhandbal</th>
                <td></td>
                <td>19:30 - 21:00 in zaal 2 (vanaf 20 uur ook zaal 1)</td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <th scope="row">HS1</th>
                <td></td>
                <td></td>
                <td></td>
                <td>
                  20:30 - 22:00 <br />
                  in zaal 1 door Marco (samen met HB1)
                </td>
                <td></td>
              </tr>
              <tr>
                <th scope="row">DA1 + DB1</th>
                <td></td>
                <td>
                  19:00 - 20:00
                  <br />
                  in zaal 1
                  <br />
                  <>Marcel, Sandy</>
                </td>
                <td></td>
                <td>
                  19:30 - 20:30 <br />
                  in zaal 2 door Petri (vanaf 20:00 ook zaal 1)
                </td>
                <td></td>
              </tr>

              <tr>
                <th scope="row">HB1</th>
                <td></td>
                <td>
                  18:30 - 19:30
                  <br />
                  in zaal 2 door Joost
                </td>

                <td></td>
                <td>
                  20:30 - 22:00
                  <br />
                  in zaal 1 door Marco (samen met HS1)
                </td>
                <td></td>
              </tr>
              <tr>
                <th scope="row">DC1 + DC2</th>
                <td></td>
                <td>
                  18:00 - 19:00
                  <br />
                  in zaal 1 door Rene
                </td>

                <td></td>
                <td>
                  18:30 - 19:30
                  <br />
                  DC2 in zaal 2 door Michael
                  <hr />
                  <strong>
                    18.30 - 19.45 <br />
                    DC1 bij Meervogels, trainer Hans Bol
                  </strong>
                </td>
                <td></td>
              </tr>
              <tr>
                <th scope="row">D1</th>
                <td></td>
                <td>
                  17:30 - 18:30
                  <br />
                  in zaal 2 door Marga + Rob
                </td>
                <td>
                  17:30 - 18:30
                  <br />
                  samen met HC in zaal 1 door Jeroen en Max
                  <br />
                </td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <th scope="row">HC</th>
                <td></td>
                <td></td>
                <td>
                  17:30 - 18:30
                  <br />
                  samen met D1 in zaal 1 door Jeroen en Max
                  <br />
                </td>
                <td>
                  19:00 - 20:00 <br />
                  in zaal 1 door Jeremy
                </td>
                <td></td>
              </tr>

              <tr>
                <th scope="row">E-jeugd</th>
                <td></td>
                <td>
                  17:00 - 18:00
                  <br />
                  in zaal 1 door René
                </td>
                <td></td>
                <td>
                  18:00 - 19:00
                  <br />
                  in zaal 1 door Iris
                </td>
                <td></td>
              </tr>
              <tr>
                <th scope="row">F-jeugd</th>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>
                  18:00 - 19:00
                  <br />
                  in zaal 3 door Melany en Annabel
                </td>
              </tr>
            </tbody>
          </Table>
        </Section>
        {/* <Section>
          <Heading variant="h2" as="h2">
            Trainers 2022 - 2023
          </Heading>
          <Table>
            <thead>
              <tr>
                <th scope="col">Team</th>
                <th scope="col">Trainer(s)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Rolstoelhandbal</td>
                <td>Jarin Plasmeijer</td>
              </tr>
              <tr>
                <td>Herensenioren</td>
                <td>Marco Buur</td>
              </tr>
              <tr>
                <td>DA1</td>
                <td>Lotte, Sander</td>
              </tr>
              <tr>
                <td>DB1</td>
                <td>Lotte, Sander</td>
              </tr>
              <tr>
                <td>HC1</td>
                <td>René en Jolanda</td>
              </tr>
              <tr>
                <td>DC1</td>
                <td>Daphne en Joost, Petri en Merel</td>
              </tr>
              <tr>
                <td>D-jeugd</td>
                <td>Daphne en Joost</td>
              </tr>
              <tr>
                <td>E-jeugd</td>
                <td>Jolanda, Jeremy</td>
              </tr>
              <tr>
                <td>F-jeugd</td>
                <td>Iris, Tessa en Melany</td>
              </tr>
            </tbody>
          </Table>
        </Section> */}
      </Container>
    </Layout>
  );
}

export default PracticeTablePage;
